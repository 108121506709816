<template>
    <b-container fluid>
        <b-overlay :show="loading">
            <b-row>
                <b-col lg="12" sm="12">
                    <iq-card>
                        <template v-slot:headerTitle>
                            <h4 class="card-title">{{ $t('dae_subsidy.verification_approval') }}</h4>
                        </template>
                        <template v-slot:body>
                            <ValidationObserver ref="form1" v-slot="{ handleSubmit, reset }">
                                <b-form @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset">
                                    <b-row>
                                        <b-col lg="6" md="6" sm="12" xs="12">
                                            <ValidationProvider name="Fiscal Year" vid="fiscal_year_id" rules="required|min_value:1">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="fiscal_year_id"
                                                    slot-scope="{ valid, errors }">
                                                    <template v-slot:label>
                                                    {{ $t('globalTrans.fiscal_year') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                    plain
                                                    v-model="search.fiscal_year_id"
                                                    :options="fiscalYearList"
                                                    id="fiscal_year_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col lg="6" md="6" sm="12" xs="12">
                                            <ValidationProvider name="Circular" vid="circular_id" rules="required|min_value:1">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="circular_id"
                                                    slot-scope="{ valid, errors }">
                                                    <template v-slot:label>
                                                    {{ $t('dae_subsidy.subsidy_circular') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                    plain
                                                    v-model="search.circular_id"
                                                    :options="circularList"
                                                    id="circular_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col lg="6" md="6" sm="12" xs="12">
                                            <ValidationProvider name="Division" vid="division_id">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="division_id"
                                                    slot-scope="{ valid, errors }">
                                                    <template v-slot:label>
                                                    {{ $t('org_pro_division.division') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                    plain
                                                    v-model="search.division_id"
                                                    :options="divisionList"
                                                    id="division_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    :disabled="isReadOnly"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col lg="6" md="6" sm="12" xs="12">
                                            <ValidationProvider name="District" vid="district_id">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="district_id"
                                                    slot-scope="{ valid, errors }">
                                                    <template v-slot:label>
                                                        {{ $t('org_pro_district.district') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                        plain
                                                        v-model="search.district_id"
                                                        :options="districtList"
                                                        id="district_id"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        :disabled="isReadOnly"
                                                        >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col lg="6" md="6" sm="12" xs="12">
                                            <ValidationProvider name="Upazilla" vid="upazilla_id">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="upazilla_id"
                                                    slot-scope="{ valid, errors }">
                                                    <template v-slot:label>
                                                        {{ $t('org_pro_upazilla.upazilla') }}
                                                    </template>
                                                    <b-form-select
                                                        plain
                                                        v-model="search.upazilla_id"
                                                        :options="upazilaList"
                                                        id="upazilla_id"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        :disabled="isReadOnly"
                                                        >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <!-- <b-col lg="6" md="6" sm="12" xs="12">
                                            <ValidationProvider name="City corporation" vid="city_corporation_id">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="city_corporation_id"
                                                    slot-scope="{ valid, errors }">
                                                    <template v-slot:label>
                                                        {{ $t('org_pro.city_corporation') }}
                                                    </template>
                                                    <b-form-select
                                                        plain
                                                        v-model="search.city_corporation_id"
                                                        :options="cityCorporationList"
                                                        id="city_corporation_id"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        :disabled="isReadOnly"
                                                        >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col> -->
                                        <!-- <b-col lg="6" md="6" sm="12" xs="12">
                                            <b-button type="submit" variant="primary">{{ $t('globalTrans.search') }}</b-button>
                                        </b-col> -->
                                    </b-row>
                                </b-form>
                            </ValidationObserver>
                            <div>
                                <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                                    <b-form @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset">
                                        <b-row>
                                            <b-col lg="12" md="12" sm="12" xs="12">
                                                <b-table-simple striped bordered small class="mt-3">
                                                    <b-thead>
                                                        <b-tr>
                                                            <b-th class="text-center">{{ $t('globalTrans.sl_no') }}</b-th>
                                                            <b-th class="text-center">{{ $t('globalTrans.application_id') }}</b-th>
                                                            <b-th class="text-center">{{ $t('globalTrans.fiscal_year') }}</b-th>
                                                            <b-th class="text-center">{{ $t('dae_subsidy.circular_name') }}</b-th>
                                                            <b-th class="text-center">{{ $t('dae_subsidy.farmer_name') }}</b-th>
                                                            <b-th class="text-center">{{ $t('globalTrans.mobile') }}</b-th>
                                                            <b-th class="text-center">{{ $t('dae_subsidy.instrument_name') }}</b-th>
                                                            <b-th class="text-center">{{ $t('globalTrans.union') }}</b-th>
                                                            <b-th class="text-center">{{ $t('dae_subsidy.priority') }}</b-th>
                                                            <b-th class="text-center">{{ $t('globalTrans.action') }}</b-th>
                                                        </b-tr>
                                                    </b-thead>
                                                    <template v-if="applicationShow">
                                                        <b-tbody>
                                                            <b-tr v-for="(application, index) in form.applications" :key="index">
                                                                <b-td>{{ $n(index + 1) }}</b-td>
                                                                <b-td>{{ $n(application.code, { useGrouping:false }) }}</b-td>
                                                                <b-td>{{ localeCheck === 'bn' ? application.fiscal_year_name_bn : application.fiscal_year_name }}</b-td>
                                                                <b-td>{{ localeCheck === 'bn' ? application.circular_name_bn : application.circular_name }}</b-td>
                                                                <b-td>
                                                                     {{ $i18n.locale === 'bn' ? application.machinery_farmer.name_bn : application.machinery_farmer.name }}
                                                                </b-td>
                                                                <b-td>{{ localeCheck === 'bn' ? '০' : '0' }}{{ $n(application.machinery_farmer.mobile_no, { useGrouping:false }) }}</b-td>
                                                                <b-td>{{ localeCheck === 'bn' ? application.instructment_name_bn : application.instructment_name }}</b-td>
                                                                <b-td>{{ localeCheck === 'bn' ? application.union_name_bn : application.union_name }}</b-td>
                                                                <b-td>
                                                                    <input
                                                                        @change="checkSorting(application, $event)"
                                                                        type="number"
                                                                        :value="application.sorting"
                                                                        style="width: 100px;margin:auto"
                                                                        class="form-control text-center"
                                                                    />
                                                                </b-td>
                                                                <b-td>
                                                                    <b-button title="View" v-b-modal.modal-5 variant=" iq-bg-success mr-1" size="sm" @click="view(application)"><i class="ri-eye-line m-0 "></i></b-button>
                                                                    <b-button title="Reject" v-b-modal.modal-6 variant=" iq-bg-danger mr-1" size="sm" @click="reject(application)"><i class="ri-close-line m-0 "></i></b-button>
                                                                </b-td>
                                                            </b-tr>
                                                        </b-tbody>
                                                    </template>
                                                    <template v-else>
                                                        <b-tbody>
                                                            <b-tr>
                                                                <b-td colspan="10" style="text-align:center"> {{ $t('globalTrans.noDataFound') }} </b-td>
                                                            </b-tr>
                                                        </b-tbody>
                                                    </template>
                                                </b-table-simple>
                                            </b-col>
                                        </b-row>
                                        <b-row v-if="applicationShow">
                                            <b-col lg="6" md="6" sm="12" xs="12">
                                                <ValidationProvider name="Office" vid="office_id" rules="required|min_value:1">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="office_id"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{ $t('globalTrans.office') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                        plain
                                                        v-model="form.office_id"
                                                        :options="officeList"
                                                        id="office_id"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                        <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" md="6" sm="12" xs="12">
                                                <ValidationProvider name="Designation" vid="designation_id" rules="required|min_value:1">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="designation_id"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                        <template v-slot:label>
                                                        {{ $t('budget.designation') }} <span class="text-danger">*</span>
                                                        </template>
                                                    <b-form-select
                                                        plain
                                                        v-model="form.designation_id"
                                                        :options="designationList"
                                                        id="designation_id"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" md="6" sm="12" xs="12">
                                                <ValidationProvider name="Officer" vid="receiver_id" rules="required|min_value:1">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="receiver_id"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                        <template v-slot:label>
                                                        {{ $t('budget.officer') }} <span class="text-danger">*</span>
                                                        </template>
                                                    <b-form-select
                                                        plain
                                                        v-model="form.receiver_id"
                                                        :options="officerList"
                                                        id="receiver_id"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="0">{{ officerLoading ? 'Loading..' : $t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col xs="12" sm="12" md="6">
                                                <ValidationProvider name="Attachment" vid="attachment" :rules="required" v-slot="{ errors }">
                                                    <b-form-group
                                                        label-cols-sm="4"
                                                        label-for="attachment">
                                                        <template v-slot:label>
                                                         {{ $t('globalTrans.attachment') }} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-file
                                                            id="attachment"
                                                            v-model="form.file"
                                                            @change="handleFile"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            placeholder="Choose a file or drop it here..."
                                                            drop-placeholder="Drop file here..."
                                                        ></b-form-file>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <!-- <b-col lg="6" md="6" sm="12" xs="12">
                                                <ValidationProvider name="Attachment" vid="attachment">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        :label="$t('circular.attachment')"
                                                        label-for="attachment"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{ $t('globalTrans.attachment') }}
                                                    </template>
                                                        <b-form-file
                                                        id="attachment"
                                                        v-on:change="handleFile"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-file>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col> -->
                                            <b-col lg="6" md="6" sm="12" xs="12">
                                                <ValidationProvider name="Comment" vid="comment" rules="required|max:200">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="comment"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                        <template v-slot:label>
                                                        {{ $t('budget.comment') }} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-textarea
                                                            plain
                                                            v-model="form.comment"
                                                            id="comment"
                                                            placeholder="Maximum 200 word.."
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                        </b-form-textarea>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                        </b-row>
                                        <div class="row" v-if="applicationShow">
                                            <div class="col-sm-3"></div>
                                            <div class="col text-right">
                                                <b-button type="submit" variant="primary" class="mr-2">{{ $t('globalTrans.save') }}</b-button>
                                                &nbsp;
                                                <b-button variant="danger" class="mr-1">
                                                    <router-link :to="{ path: '/incentive-grant-service/dae/subsidy/verification-approval'}" size="sm">
                                                    {{ $t('globalTrans.cancel') }}
                                                    </router-link>
                                                </b-button>
                                            </div>
                                        </div>
                                    </b-form>
                                </ValidationObserver>
                            </div>
                        </template>
                    </iq-card>
                </b-col>
            </b-row>
            <b-modal id="modal-5" size="xl" :title="$t('dae_subsidy.application_details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
                <Details :id="itemId" :key="item"></Details>
            </b-modal>
            <b-modal id="modal-6" size="lg" :title="$t('budget.reject')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
                <Reject :id="itemId" :status="status" :key="item"></Reject>
            </b-modal>
        </b-overlay>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { commonServiceBaseUrl, incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { applocationAll, designationWiseUser, applicationSortingForward } from '../../api/routes'
import Details from '../SubsidyAppDetails'
import Reject from './Reject'

export default {
    props: ['id'],
    components: {
        ValidationObserver,
        ValidationProvider,
        Details,
        Reject
    },
    data () {
        return {
            required: 'required',
            loading: false,
            search: {
                fiscal_year_id: 0,
                circular_id: 0,
                division_id: 0,
                district_id: 0,
                upazilla_id: 0,
                city_corporation_id: 0,
                instrument_id: 0,
                status: 1
            },
            form: {
                circular_id: 0,
                designation_id: 0,
                sender_id: this.$store.state.Auth.authUser.user_id,
                office_id: 0,
                receiver_id: 0,
                comment: '',
                file: '',
                applications: []
            },
            applicationShow: false,
            isReadOnly: false,
            officeList: [],
            officerList: [],
            officeIdList: [],
            assignDesignationList: [],
            designationList: [],
            officerLoading: false,
            item: '',
            itemId: 0,
            districtList: [],
            upazilaList: [],
            cityCorporationList: [],
            circularList: [],
            status: 0
        }
    },
    created () {
        this.officeIdList = []
        this.getOfficeIdList(this.authUser.office_id)
        this.getAssignDesignationList()
    },
    mounted () {
        core.index()
        this.officeList = this.$store.state.commonObj.officeList.filter(item => item.office_type_id === 112)
        if (this.authUser.role_id !== 1 && this.authUser.is_org_admin === 0) {
            this.isReadOnly = true
            this.search.division_id = this.authUser.office_detail.division_id
            this.search.district_id = this.authUser.office_detail.district_id
            if (this.authUser.office_detail.upazilla_id) {
                this.search.upazilla_id = this.authUser.office_detail.upazilla_id
            }
            if (this.authUser.office_detail.city_corporation_id) {
                this.search.city_corporation_id = this.authUser.office_detail.city_corporation_id
            }
        }
        if (this.$route.query.fiscal_year_id && this.$route.query.circular_id) {
            this.search.fiscal_year_id = this.$route.query.fiscal_year_id
            this.search.circular_id = this.$route.query.circular_id
            this.searchData()
        }
    },
    watch: {
        customListReload: function (newVal, oldVal) {
            if (newVal) {
                this.searchData()
            }
        },
        'form.office_id': function (newValue) {
            this.getDesignationList(newValue)
        },
        'form.designation_id': function (newValue) {
            this.getOfficerList(newValue)
        },
        'search.division_id': function (newVal, oldVal) {
            this.districtList = this.getDistrictList(newVal)
        },
        'search.district_id': function (newVal, oldVal) {
            this.cityCorporationList = this.getCityCorporationList(newVal)
            this.upazilaList = this.getUpazilaList(newVal)
        },
        'search.fiscal_year_id': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.circularList = this.getCircularList(newVal)
            }
        }
    },
    computed: {
        authUser () {
            return this.$store.state.Auth.authUser
        },
        customListReload () {
          return this.$store.state.incentiveGrant.commonObj.customListReload
        },
        localeCheck: function () {
            return this.$i18n.locale
        },
        landTypeList: function () {
            const list = [
                { value: 1, text: this.$i18n.locale === 'en' ? 'Haor' : 'হাওর' },
                { value: 2, text: this.$i18n.locale === 'en' ? 'Non-haor' : 'সমতল' }
            ]
            return list
        },
        fiscalYearList: function () {
            return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
        },
        divisionList: function () {
            return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
        },
        instrumentList () {
            const instrumentObj = this.$store.state.incentiveGrant.commonObj.instrumentList.filter(item => item.status === 1)
            return instrumentObj.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn }
                } else {
                    return { value: item.value, text: item.text_en }
                }
            })
        }
    },
    methods: {
        handleFile (e) {
            const selectedPhoto = e.target.files[0]
            const reader = new FileReader()
            reader.onload = (event) => {
                this.form.file = event.target.result
            }
            reader.readAsDataURL(selectedPhoto)
        },
        async getAssignDesignationList () {
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            const result = await RestApi.getData(commonServiceBaseUrl, 'assign-designation/list-all')
            if (result.success) {
                this.assignDesignationList = result.data
            } else {
                this.assignDesignationList = []
            }
            this.$store.dispatch('mutateCommonProperties', { loading: false })
        },
        getDesignationList (officeId) {
            const assignDesignationList = this.assignDesignationList.filter(item => item.office_id === officeId)
            this.designationList = assignDesignationList.map(item => {
                const designation = this.$store.state.commonObj.designationList.find(obj => obj.value === item.designation_id)
                return designation
            })
        },
        getOfficeIdList (officeId) {
            this.officeIdList.push(officeId)
            const office = this.$store.state.commonObj.officeList.find(item => item.value === officeId)
            if (office.parent_office_id) {
                this.getOfficeIdList(office.parent_office_id)
            }
            return this.officeIdList
        },
        getDistrictList (divisionId = null) {
            const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
            if (divisionId) {
                return districtList.filter(district => district.division_id === divisionId)
            }
            return districtList
        },
        getUpazilaList (districtId = null) {
            const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
            if (districtId) {
                return upazilaList.filter(upazila => upazila.district_id === districtId)
            }
            return upazilaList
        },
        async saveData () {
            this.loading = true
            let result = null
            this.form.circular_id = this.search.circular_id
            result = await RestApi.postData(incentiveGrantServiceBaseUrl, applicationSortingForward, this.form)
            this.loading = false

            if (result.success) {
                // push notification
                // const notification = result.notification
                // this.$socket.emit('send-notification', notification)

                this.$router.push('/incentive-grant-service/farm-machinery/allocation/application-verification-list')
                this.$toast.success({
                    title: this.$t('globalTrans.success'),
                    message: this.$t('globalTrans.save_msg'),
                    color: '#D6E09B'
                })
                this.applicationShow = false
            } else {
                this.$refs.form.setErrors(result.errors)
                this.$toast.error({
                    title: 'Error',
                    message: result.message,
                    color: '#D6E09B'
                })
            }
        },
        async getOfficerList (designationId) {
            this.officerLoading = true
            await RestApi.getData(incentiveGrantServiceBaseUrl, `${designationWiseUser}/${this.form.office_id}/${designationId}`)
            .then(response => {
                if (response.success) {
                    const data = response.data
                    this.officerList = data.map(item => {
                        return Object.assign({ value: item.user_id, text: this.$i18n.locale === 'bn' ? item.name_bn : item.name })
                    })
                } else {
                    this.officerList = []
                }
                this.officerLoading = false
            })
        },
        async searchData () {
            this.loading = true
            await RestApi.getData(incentiveGrantServiceBaseUrl, applocationAll, this.search)
            .then(response => {
                if (response.success) {
                    this.form.applications = this.getRelatinalData(response.data)
                    this.applicationShow = true
                } else {
                    this.form.applications = []
                    this.applicationShow = false
                }
                this.loading = false
                this.$store.dispatch('incentiveGrant/stateReload', false)
            })
        },
        getLandType (landTypeId) {
            const landType = this.landTypeList.find(lt => lt.value === landTypeId)
            return landType.text
        },
        view (item) {
            this.itemId = item.id
        },
        reject (item) {
            this.itemId = item.id
            this.status = 4 // 4 mean reject
        },
        getRelatinalData (data) {
            const listData = data.map(item => {
                const fiscalYear = this.$store.state.commonObj.fiscalYearList.find(fy => fy.value === item.circular.fiscal_year_id)
                const circular = this.$store.state.incentiveGrant.commonObj.circularList.find(cr => cr.value === item.circular_id)
                const division = this.$store.state.commonObj.divisionList.find(div => div.value === item.machinery_farmer.division_id)
                const district = this.$store.state.commonObj.districtList.find(district => district.value === item.machinery_farmer.district_id)
                const upazilla = this.$store.state.commonObj.upazilaList.find(upazilla => upazilla.value === item.machinery_farmer.upazilla_id)
                const union = this.$store.state.commonObj.unionList.find(union => union.value === item.machinery_farmer.union_id)
                const ward = this.$store.state.commonObj.wardList.find(ward => ward.value === item.machinery_farmer.ward_id)
                const instructment = this.$store.state.incentiveGrant.commonObj.instrumentList.find(instructment => instructment.value === item.instrument_id)
                const fiscalYearObj = { fiscal_year_name: fiscalYear !== undefined ? fiscalYear.text_en : '', fiscal_year_name_bn: fiscalYear !== undefined ? fiscalYear.text_bn : '' }
                const circularObj = { circular_name: circular !== undefined ? circular.text_en : '', circular_name_bn: circular !== undefined ? circular.text_bn : '' }
                const divisionObj = { division_name: division !== undefined ? division.text_en : '', division_name_bn: division !== undefined ? division.text_bn : '' }
                const districtObj = { district_name: district !== undefined ? district.text_en : '', district_name_bn: district !== undefined ? district.text_bn : '' }
                const upazillaObj = { upazilla_name: upazilla !== undefined ? upazilla.text_en : '', upazilla_name_bn: upazilla !== undefined ? upazilla.text_bn : '' }
                const unionObj = { union_name: union !== undefined ? union.text_en : '', union_name_bn: union !== undefined ? union.text_bn : '' }
                const wardObj = { ward_name: ward !== undefined ? ward.text_en : '', ward_name_bn: ward !== undefined ? ward.text_bn : '' }
                const instrumentObj = { instructment_name: instructment !== undefined ? instructment.text_en : '', instructment_name_bn: instructment !== undefined ? instructment.text_bn : '' }
                return Object.assign({}, item, { subsidy_app_id: item.id }, fiscalYearObj, circularObj, divisionObj, districtObj, upazillaObj, unionObj, wardObj, instrumentObj)
            })
            return listData
        },
        checkSorting: function (prevSort, event) {
            prevSort.old_sorting = prevSort.sorting
            prevSort.sorting = event.target.value
            let isSave = true
            this.form.applications.map(item => {
                if (parseInt(prevSort.sorting) === parseInt(item.sorting) && parseInt(item.id) !== parseInt(prevSort.id)) {
                    this.errorMessageShow()
                    isSave = false
                }
            })
            if (!isSave) {
                prevSort.sorting = prevSort.old_sorting
            }
        },
        errorMessageShow () {
            window.vm.$swal({
                title: window.vm.$t('globalTrans.ThisSortingOrderisallreadyused'),
                showCancelButton: false,
                confirmButtonText: 'Ok',
                cancelButtonText: 'No',
                focusConfirm: false
            })
        },
        getAddress (item) {
            const address = []
            const unionList = this.$store.state.commonObj.unionList
            const tmpUnion = unionList.find(union => union.value === item.union_id)
            if (this.$i18n.locale === 'bn') {
                address.push(item.village_bn, tmpUnion.text_bn)
            } else {
                address.push(item.village, tmpUnion.text_en)
            }
            const upazilaList = this.$store.state.commonObj.upazilaList
            const tmpUpazila = upazilaList.find(upazila => upazila.value === item.upazilla_id)
            if (this.$i18n.locale === 'bn') {
                address.push(tmpUpazila ? tmpUpazila.text_bn : '')
            } else {
                address.push(tmpUpazila ? tmpUpazila.text_en : '')
            }
            const districtList = this.$store.state.commonObj.districtList
            const tmpDistrict = districtList.find(district => district.value === item.district_id)
            if (this.$i18n.locale === 'bn') {
                address.push(tmpDistrict ? tmpDistrict.text_bn : '')
            } else {
                address.push(tmpDistrict ? tmpDistrict.text_en : '')
            }
            const divisionList = this.$store.state.commonObj.divisionList
            const tmpDivision = divisionList.find(division => division.value === item.division_id)
            if (this.$i18n.locale === 'bn') {
                address.push(tmpDivision ? tmpDivision.text_bn : '')
            } else {
                address.push(tmpDivision ? tmpDivision.text_en : '')
            }
            return address.join(', ')
        },
        getCityCorporationList (districtId) {
          const objectData = this.$store.state.commonObj.cityCorporationList.filter(item => item.status === 0 && item.district_id === districtId)
            return objectData.map((obj, key) => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.value, text: obj.text_bn }
                } else {
                    return { value: obj.value, text: obj.text_en }
                }
            })
        },
        getCircularList (fiscalYearId) {
            return this.$store.state.incentiveGrant.commonObj.circularList.filter(item => item.fiscal_year_id === fiscalYearId && item.circular_type === 1)
        }
    }
}
</script>
