<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
           <template v-slot:body>
                <template>
                    <div style="font-size:18px; background-color: #005B5B;">
                    <h5 class="text-white text-center">
                        {{ $t('admission_form.gen_inf') }}
                    </h5>
                    </div>
                </template>
               <b-overlay :show="loading">
                <b-table-simple bordered>
                        <b-tr>
                            <b-th>{{ $t('educational_management.name') }}</b-th>
                            <b-td>{{ ($i18n.locale=='bn') ? farmerInfo.name_bn : farmerInfo.name }}</b-td>
                            <b-th>{{ $t('externalUserIrrigation.father_name') }}</b-th>
                            <b-td>{{ ($i18n.locale=='bn') ? farmerInfo.father_name_bn : farmerInfo.father_name }}</b-td>
                            <b-th>{{ $t('externalUserIrrigation.mother_name') }}</b-th>
                            <b-td>{{ ($i18n.locale=='bn') ? farmerInfo.mother_name_bn : farmerInfo.mother_name }}</b-td>
                        </b-tr>
                        <b-tr>
                            <b-th>{{ $t('admission_form.mobile_no') }}</b-th>
                            <b-td>{{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(farmerInfo.mobile_no, { useGrouping: false }) }}</b-td>
                            <b-th>{{ $t('admission_form.nid_no') }}</b-th>
                            <b-td>{{ $n(farmerInfo.nid_no, { useGrouping: false }) }}</b-td>
                            <b-th>{{ $t('externalUserIrrigation.date_of_birth') }}</b-th>
                            <b-td>{{ farmerInfo.date_of_birth | dateFormat }}</b-td>
                        </b-tr>
                        <b-tr>
                            <b-th v-if="farmerInfo.email">{{ $t('admission_form.email') }}</b-th>
                            <b-td v-if="farmerInfo.email">{{ farmerInfo.email }}</b-td>
                        </b-tr>
                </b-table-simple>
                </b-overlay>
                <template>
                    <div style="font-size:18px; background-color: #005B5B;">
                    <h5 class="text-white text-center">
                        {{ $t('admission_form.add_info') }}
                    </h5>
                    </div>
                </template>
                <b-overlay :show="loading">
                    <b-table-simple bordered>
                        <b-tr>
                            <b-th colspan="6" class="text-center">{{ $t('educational_management.present_business_address') }}</b-th>
                        </b-tr>
                        <b-tr>
                            <b-th>{{ $t('org_pro.area_type') }}</b-th>
                            <b-td>{{ ($i18n.locale=='bn') ? farmerInfo.area_name_bn : farmerInfo.area_name }}</b-td>
                            <b-th>{{ $t('org_pro_division.division') }}</b-th>
                            <b-td>{{ ($i18n.locale=='bn') ? farmerInfo.div_name_bn : farmerInfo.div_name }}</b-td>
                            <b-th>{{ $t('org_pro_district.district') }}</b-th>
                            <b-td>{{ ($i18n.locale=='bn') ? farmerInfo.dis_name_bn : farmerInfo.dis_name }}</b-td>
                        </b-tr>
                        <b-tr>
                            <b-th>{{ $t('org_pro_upazilla.upazilla') }}</b-th>
                            <b-td>{{ ($i18n.locale=='bn') ? farmerInfo.upa_name_bn : farmerInfo.upa_name }}</b-td>
                            <b-th>{{ $t('org_pro_union.union') }}</b-th>
                            <b-td>{{ ($i18n.locale=='bn') ? farmerInfo.union_name_bn : farmerInfo.union_name }}</b-td>
                            <b-th v-if="farmerInfo.cityCor_name_bn && farmerInfo.cityCor_name">{{ $t('org_pro.city_corporation') }}</b-th>
                            <b-td v-if="farmerInfo.cityCor_name_bn && farmerInfo.cityCor_name">{{ ($i18n.locale=='bn') ? farmerInfo.cityCor_name_bn : farmerInfo.cityCor_name }}</b-td>
                        </b-tr>
                        <b-tr>
                            <b-th v-if="farmerInfo.per_pauro_name_bn && farmerInfo.per_pauro_name">{{ $t('org_pro.pauroshoba') }}</b-th>
                            <b-td v-if="farmerInfo.per_pauro_name_bn && farmerInfo.per_pauro_name">{{ ($i18n.locale=='bn') ? farmerInfo.pauro_name_bn : farmerInfo.pauro_name }}</b-td>
                        </b-tr>
                    </b-table-simple>
                </b-overlay>
                <b-overlay :show="loading">
                    <b-table-simple bordered>
                            <b-tr>
                                <b-th colspan="6" class="text-center">{{ $t('educational_management.permanent_address') }}</b-th>
                            </b-tr>
                            <b-tr>
                                <b-th>{{ $t('org_pro.area_type') }}</b-th>
                                <b-td>{{ ($i18n.locale=='bn') ? farmerInfo.per_area_name_bn : farmerInfo.per_area_name }}</b-td>
                                <b-th>{{ $t('org_pro_division.division') }}</b-th>
                                <b-td>{{ ($i18n.locale=='bn') ? farmerInfo.per_div_name_bn : farmerInfo.per_div_name }}</b-td>
                                <b-th>{{ $t('org_pro_district.district') }}</b-th>
                                <b-td>{{ ($i18n.locale=='bn') ? farmerInfo.per_dis_name_bn : farmerInfo.per_dis_name }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>{{ $t('org_pro_upazilla.upazilla') }}</b-th>
                                <b-td>{{ ($i18n.locale=='bn') ? farmerInfo.per_upa_name_bn : farmerInfo.per_upa_name }}</b-td>
                                <b-th>{{ $t('org_pro_union.union') }}</b-th>
                                <b-td>{{ ($i18n.locale=='bn') ? farmerInfo.per_union_name_bn : farmerInfo.per_union_name }}</b-td>
                                <b-th v-if="farmerInfo.cityCor_name_bn && farmerInfo.cityCor_name">{{ $t('org_pro.city_corporation') }}</b-th>
                                <b-td v-if="farmerInfo.cityCor_name_bn && farmerInfo.cityCor_name">{{ ($i18n.locale=='bn') ? farmerInfo.per_cityCor_name_bn : farmerInfo.per_cityCor_name }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th v-if="farmerInfo.per_pauro_name_bn && farmerInfo.per_pauro_name">{{ $t('org_pro.pauroshoba') }}</b-th>
                                <b-td v-if="farmerInfo.per_pauro_name_bn && farmerInfo.per_pauro_name">{{ ($i18n.locale=='bn') ? farmerInfo.per_pauro_name_bn : farmerInfo.per_pauro_name }}</b-td>
                            </b-tr>
                    </b-table-simple>
                </b-overlay>
                <template>
                    <div style="font-size:18px; background-color: #005B5B;">
                    <h5 class="text-white text-center">
                        {{ $t('externalIncentive.machineries_info') }}
                    </h5>
                    </div>
                </template>
               <b-overlay :show="loading">
                <b-table-simple bordered>
                        <b-tr>
                            <b-th>{{ $t('dae_subsidy.circular_name') }}</b-th>
                            <b-td>{{ ($i18n.locale=='bn') ? instrumentInfo.circular_name_bn : instrumentInfo.circular_name }}</b-td>
                            <b-th>{{ $t('dae_subsidy.instrument_name') }}</b-th>
                            <b-td>{{ ($i18n.locale=='bn') ? instrumentInfo.instrument_name_bn : instrumentInfo.instrument_name }}</b-td>
                            <b-th>{{ $t('externalIncentive.brand_name') }}</b-th>
                            <b-td>
                                {{ getColumnName($store.state.incentiveGrant.commonObj.macBrandList,instrumentInfo.brand_id)}}
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-th>{{ $t('dae_subsidy.model') }}</b-th>
                            <b-td>{{ ($i18n.locale=='bn') ? instrumentInfo.model : instrumentInfo.model }}</b-td>
                            <b-th>{{ $t('dae_subsidy.applicationType') }}</b-th>
                            <b-td colspan="3">{{ getApplicationType(instrumentInfo.application_type) }}</b-td>
                            <!-- <b-th>{{ $t('dae_subsidy.landType') }}</b-th>
                            <b-td>{{ instrumentInfo.land_type === 1 ? $t('dae_subsidy.haor') : $t('dae_subsidy.non_haor') }}</b-td> -->
                        </b-tr>
                </b-table-simple>
                </b-overlay>
                <template v-if="detailsInfo.length > 0">
                    <div style="font-size:18px; background-color: #005B5B;">
                    <h5 class="text-white text-center">
                        {{ $t('externalIncentive.farmer_list') }}
                    </h5>
                    </div>
                </template>
               <b-overlay :show="loading"  v-if="detailsInfo.length > 0">
                   <b-table-simple striped bordered small class="mt-2">
                        <b-tr>
                            <b-th>{{ $t('globalTrans.sl_no') }}</b-th>
                            <b-th>{{ $t('dae_subsidy.farmer_name') }}</b-th>
                            <b-th>{{ $t('dae_subsidy.father_name') }}</b-th>
                            <b-th>{{ $t('dae_subsidy.mobile_no') }}</b-th>
                            <b-th>{{ $t('dae_subsidy.nid') }}</b-th>
                        </b-tr>
                        <b-tr v-for="(detail,index) in detailsInfo" :key="index">
                            <b-td>{{ $n(index+1) }}</b-td>
                            <b-td>{{ ($i18n.locale === 'bn') ? detail.farmer_name_bn : detail.farmer_name }}</b-td>
                            <b-td>{{ ($i18n.locale === 'bn') ? detail.father_name_bn : detail.father_name }}</b-td>
                            <b-td>{{ ($i18n.locale == 'bn' ? '০': '0') + $n(detail.mobile_no, { useGrouping: false } ) }}</b-td>
                            <b-td>{{ $n(detail.nid, { useGrouping: false }) }}</b-td>
                        </b-tr>
                    </b-table-simple>
                </b-overlay>
           </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { applicationShow } from '../api/routes'
import flatpickr from 'flatpickr'

export default {
    props: ['id'],
    created () {
        if (this.id) {
            this.getFarmerApplicationDetails()
        }
    },
    data () {
        return {
            loader: true,
            loading: false,
            farmerInfo: [],
            instrumentInfo: [],
            detailsInfo: [],
            slOffset: 1,
            genderList: [
                { value: 1, text_en: 'Male', text_bn: 'পুরুষ' },
                { value: 2, text_en: 'Female', text_bn: 'মহিলা' },
                { value: 3, text_en: 'Other', text_bn: 'অন্যান্য' }
            ],
            bloodGroupList: [
                { value: 1, text_en: 'O-', text_bn: 'ও−' },
                { value: 2, text_en: 'O+', text_bn: 'ও+' },
                { value: 3, text_en: 'A-', text_bn: 'এ−' },
                { value: 4, text_en: 'A+', text_bn: 'এ+' },
                { value: 5, text_en: 'B-', text_bn: 'বি−' },
                { value: 6, text_en: 'B+', text_bn: 'বি+' },
                { value: 7, text_en: 'AB-', text_bn: 'এবি−' },
                { value: 8, text_en: 'AB+', text_bn: 'এবি+' }
            ],
            getAreaType: [
                { value: 1, text_en: 'City Corpoation', text_bn: 'সিটি কর্পোরেশন' },
                { value: 2, text_en: 'Pauroshoba', text_bn: 'পৌরশোবা' },
                { value: 3, text_en: 'Union', text_bn: 'ইউনিয়ন' }
           ]
        }
    },
    mounted () {
        core.index()
        flatpickr('#registration_date', {})
    },
    computed: {
        localeCheck: function () {
            return this.$i18n.locale
        }
    },
    methods: {
        getColumnName (list, groupId) {
            const obj = list.find(item => item.value === groupId)
            if (typeof obj !== 'undefined') {
                return obj.text_bn
            } else {
                return ''
            }
        },
        async getFarmerApplicationDetails () {
            this.loading = true
            const result = await RestApi.getData(incentiveGrantServiceBaseUrl, `${applicationShow}/${this.id}`)
            if (result.success) {
                this.instrumentInfo = this.getInstrumentData(result.data)
                this.farmerInfo = this.getRelationalAddressData(result.data.machinery_farmer)
                this.detailsInfo = result.data.details
            }
            this.loading = false
        },
        getRelationalData (data) {
            const genderType = this.genderList.find(gender => gender.value === parseInt(data.gender_id))
            const bloodType = this.bloodGroupList.find(blood => blood.value === parseInt(data.blood_group_id))
            const educationType = this.$store.state.incentiveGrant.commonObj.educationLevelList.find(education => education.value === data.education_level_id)
            return Object.assign({}, data,
              {
                gender_name: genderType !== undefined ? genderType.text_en : '',
                gender_name_bn: genderType !== undefined ? genderType.text_bn : '',
                blood_name: bloodType !== undefined ? bloodType.text_en : '',
                blood_name_bn: bloodType !== undefined ? bloodType.text_bn : '',
                education_label: educationType !== undefined ? educationType.text_en : '',
                education_label_bn: educationType !== undefined ? educationType.text_bn : ''
              }
            )
        },
        getRelationalAddressData (data) {
            const areaList = this.getAreaType.find(aType => aType.value === parseInt(data.area_type_id))
            const divList = this.$store.state.commonObj.divisionList.find(div => div.value === data.division_id)
            const disList = this.$store.state.commonObj.districtList.find(dis => dis.value === data.district_id)
            const upaList = this.$store.state.commonObj.upazilaList.find(upa => upa.value === data.upazilla_id)
            const unionList = this.$store.state.commonObj.unionList.find(union => union.value === data.union_id)
            const cityCorList = this.$store.state.commonObj.cityCorporationList.find(city => city.value === data.city_corporation_id)
            const pauroList = this.$store.state.commonObj.pauroshobaList.find(pauro => pauro.value === data.pauroshoba_id)
            const wardList = this.$store.state.commonObj.wardList.find(ward => ward.value === data.ward_id)

            const areaListPer = this.getAreaType.find(aType => aType.value === parseInt(data.area_type_id))
            const divListPer = this.$store.state.commonObj.divisionList.find(div => div.value === data.per_division_id)
            const disListPer = this.$store.state.commonObj.districtList.find(dis => dis.value === data.per_district_id)
            const upaListPer = this.$store.state.commonObj.upazilaList.find(upa => upa.value === data.per_upazilla_id)
            const unionListPer = this.$store.state.commonObj.unionList.find(union => union.value === data.per_union_id)
            const cityCorListPer = this.$store.state.commonObj.cityCorporationList.find(city => city.value === data.per_city_corporation_id)
            const pauroListPer = this.$store.state.commonObj.pauroshobaList.find(pauro => pauro.value === data.per_pauroshoba_id)
            const wardListPer = this.$store.state.commonObj.wardList.find(ward => ward.value === data.per_ward_id)
            return Object.assign({}, data,
                { area_name: areaList !== undefined ? areaList.text_en : '', area_name_bn: areaList !== undefined ? areaList.text_bn : '' },
                { div_name: divList !== undefined ? divList.text_en : '', div_name_bn: divList !== undefined ? divList.text_bn : '' },
                { dis_name: disList !== undefined ? disList.text_en : '', dis_name_bn: disList !== undefined ? disList.text_bn : '' },
                { upa_name: upaList !== undefined ? upaList.text_en : '', upa_name_bn: upaList !== undefined ? upaList.text_bn : '' },
                { union_name: unionList !== undefined ? unionList.text_en : '', union_name_bn: unionList !== undefined ? unionList.text_bn : '' },
                { cityCor_name: cityCorList !== undefined ? cityCorList.text_en : '', cityCor_name_bn: cityCorList !== undefined ? cityCorList.text_bn : '' },
                { pauro_name: pauroList !== undefined ? pauroList.text_en : '', pauro_name_bn: pauroList !== undefined ? pauroList.text_bn : '' },
                { ward_name: wardList !== undefined ? wardList.text_en : '', ward_name_bn: wardList !== undefined ? wardList.text_bn : '' },

                { village_en: data.village_en, village_bn: data.village_bn },
                { block_en: data.block_en, block_bn: data.block_bn },

                { per_area_name: areaListPer !== undefined ? areaListPer.text_en : '', per_area_name_bn: areaListPer !== undefined ? areaListPer.text_bn : '' },
                { per_div_name: divListPer !== undefined ? divListPer.text_en : '', per_div_name_bn: divListPer !== undefined ? divListPer.text_bn : '' },
                { per_dis_name: disListPer !== undefined ? disListPer.text_en : '', per_dis_name_bn: disListPer !== undefined ? disListPer.text_bn : '' },
                { per_upa_name: upaListPer !== undefined ? upaListPer.text_en : '', per_upa_name_bn: upaListPer !== undefined ? upaListPer.text_bn : '' },
                { per_union_name: unionListPer !== undefined ? unionListPer.text_en : '', per_union_name_bn: unionListPer !== undefined ? unionListPer.text_bn : '' },
                { per_cityCor_name: cityCorListPer !== undefined ? cityCorListPer.text_en : '', per_cityCor_name_bn: cityCorListPer !== undefined ? cityCorListPer.text_bn : '' },
                { per_pauro_name: pauroListPer !== undefined ? pauroListPer.text_en : '', per_pauro_name_bn: pauroListPer !== undefined ? pauroListPer.text_bn : '' },
                { per_ward_name: wardListPer !== undefined ? wardListPer.text_en : '', per_ward_name_bn: wardListPer !== undefined ? wardListPer.text_bn : '' },

                { per_village_en: data.per_village_en, per_village_bn: data.per_village_bn },
                { per_block_en: data.per_block_en, per_block_bn: data.per_block_bn }
            )
        },
        getInstrumentData (data) {
            const instrumentList = this.$store.state.incentiveGrant.commonObj.instrumentList.find(instrument => instrument.value === data.instrument_id)
            const circularList = this.$store.state.incentiveGrant.commonObj.circularList.find(circular => circular.value === data.circular_id)
            return Object.assign({}, data,
              {
                instrument_name: instrumentList !== undefined ? instrumentList.text_en : '',
                instrument_name_bn: instrumentList !== undefined ? instrumentList.text_bn : '',
                circular_name: circularList !== undefined ? circularList.text_en : '',
                circular_name_bn: circularList !== undefined ? circularList.text_bn : ''
              }
            )
        },
        getApplicationType (type) {
            if (type === 1) {
                return this.localeCheck === 'en' ? 'Individual' : 'ব্যক্তিগত'
            } else {
                return this.localeCheck === 'en' ? 'Group' : 'গ্রুপ'
            }
        }
    }
}
</script>
